.project-item-container {
  margin-top: 20px;
}

.project-title {
  font-family: "Bitter", serif;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}

.project-item-main-image {
  margin-top: 32px;
  width: 640px;
}

.project-item-header {
  margin-top: 40px;
  width: 50%;
  font-family: "Yantramanav", sans-serif;
  font-size: 32px;
  line-height: 41.5px;
  color: #2e2e2e;
}

.project-item-description {
  margin-top: 20px;
  width: 50%;
  font-size: 20px;
  line-height: 32px;
  font-family: "Yantramanav", sans-serif;
}

a.project-link-hover-underline {
  text-decoration: none;
  color: #8a3a46;
  position: relative;
}

a.project-link-hover-underline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #8a3a46;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

a.project-link-hover-underline:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
