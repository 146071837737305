.contact-container {
  margin-top: 200px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contact-title {
  font-family: "Bitter", serif;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}

.contact-description {
  margin-top: 20px;
  font-size: 20px;
  line-height: 32px;
  font-family: "Yantramanav", sans-serif;
}

form {
  width: 100%;
  height: auto;
  border-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-body {
  margin-top: 20px;
}

.form-control {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #8a3a46;
  font-family: "Yantramanav", sans-serif;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 10px;
  border-radius: 0;
}

.form-body button {
  width: 225px;
  height: 40px;
  margin-top: 20px;
  font-family: "Yantramanav", sans-serif;
  font-size: 24px;
  letter-spacing: 2px;
  background-color: #8a3a46;
  color: white;
  border-radius: 10px;
}

.form-body button:hover {
  opacity: 0.8;
}

.g-recaptcha {
  margin-top: 20px;
}

@media screen and (max-width: 1280px) {
  .contact-container {
    margin-top: 50px;
    width: 95%;
  }
}
