.homepage-container-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.homepage-welcome-text-mobile {
  margin-left: 16px;
  font-size: 32px;
  line-height: 41.5px;
}

.homepage-statement-text-mobile {
  margin-left: 16px;
  font-size: 24px;
  line-height: 32px;
}

.homepage-text-mobile {
  margin-left: 16px;
  font-size: 16px;
  line-height: 20.75px;
}

.project-item-mobile {
  width: 100%;
  height: auto;
  padding: 20px 0;
  border-bottom: 5px solid #8a3a46;
  text-align: center;
  color: #595959;
}

.project-content-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-img-mobile {
  width: 300px;
  height: auto;
}

.project-title-mobile {
  width: 100%;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.project-title-text-mobile {
  font-size: 24px;
  line-height: 31px;
}

.project-description-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #2e2e2e;
  text-align: left;
}

.project-item-header-mobile {
  margin-top: 40px;
  width: 95%;
  font-family: "Yantramanav", sans-serif;
  font-size: 32px;
  line-height: 41.5px;
  color: #2e2e2e;
}

.project-item-description-mobile {
  margin-top: 20px;
  width: 95%;
  font-size: 20px;
  line-height: 32px;
  font-family: "Yantramanav", sans-serif;
}

a.project-link-hover-underline-mobile {
  text-decoration: none;
  color: #8a3a46;
  position: relative;
}

a.project-link-hover-underline-mobile:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #8a3a46;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

a.project-link-hover-underline-mobile:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
