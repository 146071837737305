.about-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-title {
  font-family: "Bitter", serif;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}

.about-content {
  width: 80%;
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
}

.about-content-image {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.about-main-image {
  width: 250px;
}

.about-text-content {
  margin-left: 50px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.about-text {
  margin-top: 5px;
  font-size: 24px;
  line-height: 32px;
  font-family: "Yantramanav", sans-serif;
}

.contact-link-hover-underline {
  text-decoration: none;
  color: #8a3a46;
  position: relative;
}

.contact-link-hover-underline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #8a3a46;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.contact-link-hover-underline:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.social-logo {
  width: 24px;
}

.social-link:hover {
  opacity: 0.5;
}

@media screen and (max-width: 1280px) {
  .about-container {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .about-content {
    margin-top: 50px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about-content-image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about-text-content {
    margin: 32px 0 0 0;
    width: 100%;
  }

  .about-text-title {
    font-size: 32px;
    line-height: 38.4px;
  }

  .about-text {
    margin-top: 16px;
    font-size: 20px;
    line-height: 28px;
  }

  .social-row {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}
