.navbar-container {
  border-bottom: 5px solid #8a3a46;
  background-color: #fff;
  position: fixed;
  z-index: 1;
}

.home-container {
  width: 100%;
  padding: 0;
  margin: 0;
}

.featured-projects-row {
  width: 100%;
  margin: 0;
}

.featured-col {
  border-right: 5px solid #8a3a46;
  overflow-y: scroll;
  position: fixed;
  left: 0;
  height: 100vh;
}

.projects-col {
  overflow-y: scroll;
  height: 91%;
  position: fixed;
  top: 85px;
  right: 0;
}

@media screen and (max-width: 1280px) {
  .App {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
