.projects-container {
  width: 100%;
  padding: 0;
  margin: 0;
}

.project-item {
  height: 300px;
  border-bottom: 5px solid #8a3a46;
  text-align: center;
  color: #595959;
}

.project-item:hover {
  background-color: #8a3a46;
  color: #ffffff;
}

.project-img {
  width: 294px;
}

.leftArrow {
  font-size: 32px;
}

.project-title-text {
  padding-left: 20px;
  font-size: 32px;
}
