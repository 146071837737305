.homepage-container {
  margin: 15% 0 0 0;
  padding: 0;
}

img {
  width: 100%;
}

.homepage-welcome-text {
  margin: 0;
  padding: 0;
  font-family: "Bitter", serif;
  font-size: 44px;
  line-height: 51px;
  font-weight: 700;
}

.homepage-statement-text {
  margin: 32px 0 0 0;
  font-family: "Yanatramanav", sans-serif;
  font-size: 32px;
  line-height: 41.5px;
  font-weight: 400;
}

.homepage-text {
  margin-top: 40px;
  font-family: "Yantramanav", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
}

.large-logo {
  width: 140px;
}

.medium-logo {
  width: 100px;
}

.small-logo {
  width: 60px;
}

.python-logo {
  position: fixed;
  top: 12%;
  left: 20%;
}

.nodejs-logo {
  position: fixed;
  top: 25%;
  left: 35%;
}

.css-logo {
  position: fixed;
  top: 18%;
  left: 48%;
}

.react-logo {
  position: fixed;
  top: 35%;
  left: 60%;
}

.postgresql-logo {
  position: fixed;
  top: 50%;
  left: 52%;
}

.code-logo {
  position: fixed;
  top: 62%;
  left: 67%;
}

.express-logo {
  position: fixed;
  top: 82%;
  left: 56%;
}

.html-logo {
  position: fixed;
  top: 68%;
  left: 49%;
}

.javascript-logo {
  position: fixed;
  top: 75%;
  left: 30%;
}

.knex-logo {
  position: fixed;
  top: 88%;
  left: 20%;
}

@media screen and (max-width: 1280px) {
  .large-logo,
  .medium-logo,
  .small-logo {
    display: none;
  }
}
