* {
  font-family: "Bitter", serif;
  font-family: "Yantramanav", sans-serif;
}

.nav-container {
  background-color: #fff;
}

.nav-row {
  width: 100%;
  margin: 10px 0 0 20px;
}

.nav-name {
  font-family: "Bitter", serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  color: #262626;
}

.nav-job-title {
  font-size: 20px;
  line-height: 22px;
  color: #595959;
}

.navbar-link {
  font-size: 20px;
  color: #595959;
}

a.nav-name-hover-underline {
  text-decoration: none;
  color: inherit;
  position: relative;
}

a.nav-name-hover-underline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #8a3a46;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

a.nav-name-hover-underline:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

a.nav-link-hover-underline {
  text-decoration: none;
  color: inherit;
  position: relative;
}

a.nav-link-hover-underline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #8a3a46;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

a.nav-link-hover-underline:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

@media screen and (max-width: 1280px) {
  .nav-container {
    border-bottom: 5px solid #8a3a46;
  }

  .nav-row {
    margin: 10px 0 0 0;
  }

  .nav-icon {
    width: 32px;
  }

  .menu-items {
    position: fixed;
    width: 40%;
    right: 0px;
    top: 90px;
    height: 100%;
    flex-direction: column;
    background-color: #8a3a46;
    color: #fff;
    border-radius: 10px 0;
  }

  .navbar-link {
    margin-top: 30px;
    text-align: center;
    font-size: 24px;
    line-height: 28px;
  }

  a.nav-link-hover-underline:before {
    background-color: #fff;
  }
}
