.error-container {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 32px;
}

.error-link {
  text-decoration: none;
}

a.error-link {
  text-decoration: none;
  color: #8a3a46;
  position: relative;
}

a.error-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #8a3a46;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

a.error-link:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
